var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container" }, [
              _vm.type == "folder"
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("form"),
                    _c("p", { staticClass: "header" }, [
                      _vm._v("请输入文件夹名"),
                    ]),
                    _c("p", { staticClass: "input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.foldername,
                            expression: "foldername",
                          },
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.foldername },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.foldername = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "btn" }, [
                      _c(
                        "button",
                        { staticClass: "cancle", on: { click: _vm.cancle } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "confirm",
                          on: { click: _vm.confirmFolder },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type == "childFolder"
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("p", { staticClass: "header" }, [
                      _vm._v("请输入子级文件夹名"),
                    ]),
                    _c("p", { staticClass: "input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.foldername,
                            expression: "foldername",
                          },
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.foldername },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.foldername = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "btn" }, [
                      _c(
                        "button",
                        { staticClass: "cancle", on: { click: _vm.cancle } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "confirm",
                          on: { click: _vm.confirmChildFolder },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type == "file"
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("p", { staticClass: "header" }, [
                      _vm._v("请输入文件名"),
                    ]),
                    _c("p", { staticClass: "input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.foldername,
                            expression: "foldername",
                          },
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.foldername },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.foldername = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "btn" }, [
                      _c(
                        "button",
                        { staticClass: "cancle", on: { click: _vm.cancle } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "confirm",
                          on: { click: _vm.confirmFile },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type == "childFile"
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("p", { staticClass: "header" }, [
                      _vm._v("请输入子级文件名"),
                    ]),
                    _c("p", { staticClass: "input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.foldername,
                            expression: "foldername",
                          },
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.foldername },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.foldername = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "btn" }, [
                      _c(
                        "button",
                        { staticClass: "cancle", on: { click: _vm.cancle } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "confirm",
                          on: { click: _vm.confirmChildFile },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type == "rename"
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("p", { staticClass: "header" }, [
                      _vm._v("请输入新名称"),
                    ]),
                    _c("p", { staticClass: "input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.foldername,
                            expression: "foldername",
                          },
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.foldername },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.foldername = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "btn" }, [
                      _c(
                        "button",
                        { staticClass: "cancle", on: { click: _vm.cancle } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "confirm",
                          on: { click: _vm.confirmName },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type == "remove"
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("p", { staticClass: "header" }, [_vm._v("确认删除?")]),
                    _c("p", { staticClass: "btn" }, [
                      _c(
                        "button",
                        { staticClass: "cancle", on: { click: _vm.cancle } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "confirm",
                          on: { click: _vm.confirmDelete },
                        },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type == "upload"
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("form", { attrs: { action: "" } }, [
                      _c("input", { attrs: { type: "file" } }),
                    ]),
                    _c("p", { staticClass: "btn" }, [
                      _c(
                        "button",
                        { staticClass: "cancle", on: { click: _vm.cancle } },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "button",
                        { staticClass: "confirm", on: { submit: _vm.upload } },
                        [_vm._v("确定")]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }