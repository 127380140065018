<template>
  <div class="main">
    <div class="search">
      <i class="iconfont icon-search"></i>
      <input type="search"
             @input="Select()"
             v-model="searchValue"
             placeholder="检索案件信息">
    </div>
    <div class="classify">
      <span class="iconfont icon-back"
            @click="moveLeft"></span>
      <span class="classes-0"
            @click="firstType"
            :class="{'active':this.selected==1}">民事</span>
      <span class="classes-1"
            @click="secondType"
            :class="{'active':this.selected==2}">刑事</span>
      <span class="classes-2"
            @click="thirdType"
            :class="{'active':this.selected==3}">执行</span>
      <span class="classes-3"
            @click="forthType"
            :class="{'active':this.selected==4}">行政</span>
      <span class="iconfont icon-gengduo"
            @click="moveRight"></span>
    </div>
    <div id="container">
      <div class="test">
        <span @click="backLast">...</span>
        <template v-if="!data.datas">
          <div class="cell"
               v-for="item in data"
               :key="item.index">
            <p class="item">
              <span class="iconfont icon-folder"
                    v-if="item.next"></span>
              <span class="iconfont icon-doc"
                    v-if="!item.next"></span>
              <!--<span v-if="this.searchValue == ''">{{item.text}}</span>-->
              <span @click="enterDetail(item)"
                    v-html="item.text.replace(searchValue,`<span style='color:red'>${searchValue}</span>`)">
                {{item.text}}
              </span>
            </p>
            <p class="icon">
              <i class="iconfont icon-info hascolor"></i>
              <i class="iconfont icon-mine hascolor"
                 @click="getKnowledge(item)"></i>
              <i class="iconfont icon-share-2 hascolor"
                 @click="shareKnowledge(item)"></i>
            </p>
          </div>
        </template>
        <div class="load"
             @click="loadMore"
             v-if="!data.datas">加载更多</div>
        <div class="detail"
             v-html="data.datas"
             v-if="data.datas"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { caseBase } from '~api-config'
export default {
  name: 'CaseFile',
  data () {
    return {
      searchValue: '',
      page: 1,
      ShowChild: false,
      ShowDetail: false,
      controller: '',
      currentId: '',
      selected: 1,
      typeArr: [],
      indexArray: [],
      criminalData: [],
      civilData: [],
      implementData: [],
      administrativeData: [],
      data: [
      ]
    }
  },
  components: {

  },
  methods: {
    enterDetail (data) {
      console.log(data.id)
      if (data.next) {
        axios.get(caseBase + '/farbun/systemTree/list/' + data.id + '')
          .then((res) => {
            console.log(res, '???')
            console.log(res.data.data)
            this.currentId = res.data.data.id
            this.data = res.data.data.children
            console.log(this.data)
            this.indexArray = [...this.indexArray, this.data]
            // this.indexArray.push(this.data);
            console.log(this.indexArray)
            console.log(this.indexArray[this.indexArray.length - 1])
          })
      } else {
        axios.get(caseBase + '/farbun/systemTree/document?documentId=' + data.id + '')
          .then((res) => {
            console.log(res, '???')
            this.data = res.data.data

            console.log(res.data.data)
          })
      }
    },
    backLast () {
      if (this.indexArray.length > 1) {
        this.data = this.indexArray[this.indexArray.length - 1]
        this.indexArray.pop()
      } else {
        console.log(this.data)
        switch (this.selected) {
          case 1:
            this.data = this.civilData
            break
          case 2:
            this.data = this.criminalData
            break
          case 3:
            this.data = this.administrativeData
            break
          case 4:
            this.data = this.implementData
            break
          default:
            this.data = this.civilData
        }
      }
    },
    Select () {
      console.log('input')
      axios.get(caseBase + '/farbun/systemTree/search?title=' + this.searchValue + '')
        .then((res) => {
          console.log(res, '???')
          console.log(res.data.data)
          this.data = res.data.data.children
        })
    },
    firstType () {
      this.selected = 1
      console.log(this.typeArr)
      axios.get(caseBase + '/farbun/systemTree/list/' + this.typeArr[0].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.civilData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    secondType () {
      this.selected = 2
      axios.get(caseBase + '/farbun/systemTree/list/' + this.typeArr[1].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.criminalData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    thirdType () {
      this.selected = 3
      axios.get(caseBase + '/farbun/systemTree/list/' + this.typeArr[2].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.implementData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    forthType () {
      this.selected = 4
      axios.get(caseBase + '/farbun/systemTree/list/' + this.typeArr[3].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.administrativeData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    moveLeft () {
      console.log('move left')
      this.selected = this.selected - 1
      if (this.selected === 0) {
        this.selected = 4
      };
      console.log('pass')
      switch (this.selected) {
        case 1:
          this.firstType()
          break
        case 2:
          this.secondType()
          break
        case 3:
          this.thirdType()
          break
        case 4:
          this.forthType()
          break
        default:
          console.log(this.selected)
      }
    },
    moveRight () {
      console.log('move right')
      this.selected = this.selected + 1
      if (this.selected === 5) {
        this.selected = 1
      };
      switch (this.selected) {
        case 1:
          this.firstType()
          break
        case 2:
          this.secondType()
          break
        case 3:
          this.thirdType()
          break
        case 4:
          this.forthType()
          break
        default:
          console.log(this.selected)
      }
    },
    getKnowledge (item) {
      console.log(item)
      axios.get(caseBase + '/farbun/systemTree/use?id=' + item.id + '&userId=100')
        .then(res => {
          console.log(res.status)
        })
    },

    shareKnowledge (item) {

    },
    loadMore () {
      console.log('get')
      let page = Math.ceil(this.data.length / 10)
      page++
      console.log(page)
      console.log(this.currentId)
      axios.get(caseBase + '/farbun/systemTree/queryMore?id=' + this.currentId + '&userId=100&currPage=' + page + '')
        .then(res => {
          console.log(res.data.data)
          this.data = this.data.concat(res.data.data.children)
          console.log(this.data)
        })
    }
  },
  mounted () {
    axios.get(caseBase + '/farbun/systemTree/top')
      .then((res) => {
        console.log(res.data.data)
        this.typeArr = res.data.data.systemTree
        console.log(this.typeArr)
      })
    console.log('entry')
    axios.get(caseBase + '/farbun/systemTree/list/c28ffd6c-59ef-4f76-8928-e9eb5606bd7b')
      .then(res => {
        console.log(res.data.data.id)
        this.currentId = res.data.data.id
        this.data = res.data.data.children
        this.civilData = res.data.data.children
        this.indexArray = [...this.indexArray, this.data]
        // this.indexArray.push(this.data)
        console.log(this.data)
        console.log(this.indexArray)
      })
  }
}
</script>
<style scoped>
.load {
  text-align: center;
}
div.search {
  margin: 5px;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 5px;
}
input {
  display: inline-block;
  height: 20px;
  padding: 0;
  margin: 0;
  width: 80vw;
  border: none;
  text-decoration: none;
}
input:focus {
  border: none;
  outline: none;
}
.classify > span {
  font-size: 0.5rem;
}
span {
  display: inline-block;
  padding: 5px;
  /* font-size: 0.5rem; */
}
.active {
  color: #1488ff;
}
button {
  border: none;
  background-color: #fff;
}
.classify {
  display: flex;
  border: 1px solid #1488ff;
  border-radius: 5px;
  margin: 5px;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}
div.cell {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: solid 1px #eeeeee;
  margin: 10px;
}

p {
  display: inline-block;
}
p.icon span {
  color: #1488ff;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon {
  width: auto;
  height: auto;
  vertical-align: middle;
  width: 20vw;
}
.item {
  width: 79vw;
}
.hascolor {
  color: #1488ff;
}
a {
  color: #000;
}
</style>
