<template>
  <div class="container">
    <NavBar slot="header"
            :title="title"
            fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button>
    </NavBar>
    <p class="tabbar">
      <span class="tab"
            @click="ShowCase"
            :class="{'active':Switch===1}">案件档案</span>
      <span class="tab"
            @click="ShowSystem"
            :class="{'active':Switch===2}">系统知识</span>
      <span class="tab"
            @click="ShowMine"
            :class="{'active':Switch===3}">我的知识</span>
    </p>
    <CaseFile v-show="Switch===1"></CaseFile>
    <SystemKnowledge v-show="Switch===2"></SystemKnowledge>
    <MyKnowledge v-show="Switch===3"></MyKnowledge>

  </div>
</template>
<script>
import SystemKnowledge from './components-detail/SystemKnowledge'
import MyKnowledge from './components-detail/MyKnowledge'
import CaseFile from './components-detail/CaseFile'

export default {
  name: 'Tree',
  components: {
    CaseFile,
    MyKnowledge,
    SystemKnowledge
  },
  data () {
    return {
      Switch: 1,
      title: '知识树'
    }
  },

  methods: {
    ShowCase () {
      console.log('test')
      this.Switch = 1
    },
    ShowSystem () {
      console.log('test2')
      this.Switch = 2
    },
    ShowMine () {
      console.log('test1')
      this.Switch = 3
    }

  }

}
</script>
<style scoped>
.tabbar {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}
span.tab {
  font-size: 0.5rem;
}
span {
  display: inline-block;
}
.active {
  /*background-color: #89af89;*/
  color: #3eb3f0;
}
div.main {
  margin-top: 10px;
}
div.search {
  margin-top: 0;
}
</style>
