<template>
  <div class="main">
    <div class="search">
      <i class="iconfont icon-search"></i> <input type="text"
             @input="Select"
             v-model="searchValue"
             class="iconfont icon-search"
             placeholder=" 输入案件信息">
      <select name=""
              id="select"
              v-model="showType"
              @click="selectType">
        <option value="0">默认</option>
        <option value="1">倒序</option>
      </select>
    </div>
    <span class="back"
          @click="toBack">...</span>
    <FolderTreeCase :myCaseArray="calcCaseArray"
                    @toChild="toChild"
                    showItem="case"></FolderTreeCase>
  </div>
</template>
<script>
import FolderTreeCase from './FolderTreeCase'
export default {
  name: 'SystemKnowledge',
  data () {
    return {
      searchValue: '',
      nameList: [],
      second: false,
      third: false,
      EditorSwitch: false,
      sendVal: false,
      showType: 0,
      one: [],
      two: [],
      three: [],
      four: [],
      Grade: 0,
      indexArray: [],
      appearPosition: '',
      data: [
        { id: '1', pid: '00', content: 'jack', hasChildren: true, children: [{ id: 11, content: 'hello', hasChildren: true, children: [{ id: '111', content: 'world' }] }], reference: [{ name: 'jack', item: [{ name: 'jack', info: '自然人', address: '江苏南京', phone: '183273273' }, { name: '山东法奔信息科技有限公司', info: '法人单位', address: '江苏南京', phone: '183273273' }] }, { name: 'rose', item: [{ name: 'jacky', info: '自然人', address: '江苏南京', phone: '183273273' }, { name: '山东法奔信息科技有限公司', info: '法人单位', address: '江苏南京', phone: '183273273' }] }] },
        { id: '2', pid: '01', content: 'rose', hasChildren: true, children: [{ id: 11, content: 'hello', hasChildren: true, children: [{ id: '111', content: 'world' }] }], reference: [{ name: 'jack', item: [{ name: 'rose', info: '自然人', address: '江苏南京', phone: '183273273' }, { name: '山东法奔信息科技有限公司', info: '法人单位', address: '江苏南京', phone: '183273273' }] }] },
        { id: '3', pid: '02', content: 'tom', hasChildren: true, children: [{ id: 11, content: 'hello', hasChildren: true, children: [{ id: '111', content: 'world' }] }], reference: [{ name: 'jack', item: [{ name: 'tom', info: '自然人', address: '江苏南京', phone: '183273273' }, { name: '山东法奔信息科技有限公司', info: '法人单位', address: '江苏南京', phone: '183273273' }] }] },
        { id: '4', pid: '03', content: 'jerry', hasChildren: true, children: [{ id: 11, content: 'hello', hasChildren: true, children: [{ id: '111', content: 'world' }] }], reference: [{ name: 'jack', item: [{ name: 'jerry', info: '自然人', address: '江苏南京', phone: '183273273' }, { name: '山东法奔信息科技有限公司', info: '法人单位', address: '江苏南京', phone: '183273273' }] }] }
      ]

    }
  },
  components: {
    FolderTreeCase: FolderTreeCase
  },
  methods: {
    Select () {
      console.log('input')
      console.log(this.$refs)
    },
    selectType () {
      console.log(this.showType)
    },
    changeData (index) {
      console.log(index)
      console.log(this.data)
      if (this.data[index].children) {
        switch (this.Grade) {
          case 0:
            this.one = this.data
            console.log(this.one, 'hello', this.Grade)
            break
          case 1:
            this.two = this.data
            console.log(this.two)
            console.log(this.Grade)
            break
          case 2:
            this.three = this.data
            console.log(this.three)
            console.log(this.Grade)
            break
          case 3:
            this.four = this.data
            console.log(this.four)
            console.log(this.Grade)
            break
          default:
            console.log(this.Grade)
        }
        this.data = this.data[index].children
        this.Grade = this.Grade + 1
        console.log(this.Grade)
      } else {
        this.data = [this.data[index]]
      }
    },
    toChild (index) {
      this.indexArray.push(index)
    },
    toBack () {
      if (this.indexArray.length) {
        this.indexArray.pop()
      } else {
        console.log('第一页')
        // 可以用this.$router.back()返回上一个路由;
      }
    }

  },
  computed: {
    calcCaseArray () {
      var array = this.data
      this.indexArray.forEach((item, index) => {
        console.log('entry')
        array = array[item].children
      })
      console.log(array)
      return array
    }

  },

  beforeMount () {
    var userArr = this.data.map(user => user.reference)
    console.log(userArr)
    this.$store.commit('userArr', userArr)
  },
  mounted () {

  },
  updated () {

  }
}
</script>
<style scoped>
span {
  display: inline-block;
}
.active {
  color: #1488ff;
}

.icon {
  float: right;
  color: #1488ff;
}

.cell {
  padding: 5px;
  border-bottom: solid 1px #eeeeee;
  margin: 20px;
}

select {
  height: 22px;

  border: none;
}
div.search {
  margin: 5px;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 5px;
}
input {
  display: inline-block;
  height: 20px;
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: none;
  width: 70vw;
}
input:focus,
select:focus,
option:focus {
  border: none;
  outline: none;
}
#select option {
  border: none;
  background: #fff;
  font-size: 12px;
}
</style>
