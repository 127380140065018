var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      {
        staticClass: "v-popover-tag",
        on: {
          click: function ($event) {
            return _vm.pop($event)
          },
        },
      },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      {
        ref: "pop",
        staticClass: "v-popover-wrap",
        style: {
          left: _vm.x + "px",
          top: _vm.y + "px",
          visibility: _vm.show ? "visible" : "hidden",
        },
      },
      [
        _c(
          "div",
          { staticClass: "v-popover-box" },
          [
            _c("p", { staticClass: "folder", on: { click: _vm.creatFolder } }, [
              _c("span", { staticClass: "iconfont icon-folder" }),
              _c("span", { staticClass: "title" }, [_vm._v("新建文件夹")]),
            ]),
            _vm.showDialog === 0
              ? _c("Dialog", {
                  attrs: { type: "folder" },
                  on: {
                    close: function ($event) {
                      _vm.showDialog = -1
                    },
                  },
                })
              : _vm._e(),
            _c(
              "p",
              { staticClass: "folder", on: { click: _vm.creatChildFolder } },
              [
                _c("span", { staticClass: "iconfont icon-folder" }),
                _c("span", { staticClass: "title" }, [
                  _vm._v("新建子级文件夹"),
                ]),
              ]
            ),
            _vm.showDialog === 1
              ? _c("Dialog", {
                  attrs: { type: "childFolder" },
                  on: {
                    close: function ($event) {
                      _vm.showDialog = -1
                    },
                  },
                })
              : _vm._e(),
            _c("p", { staticClass: "folder", on: { click: _vm.creatFile } }, [
              _c("span", { staticClass: "iconfont icon-doc" }),
              _c("span", { staticClass: "title" }, [_vm._v("新建文件")]),
            ]),
            _vm.showDialog === 2
              ? _c("Dialog", {
                  attrs: { type: "file" },
                  on: {
                    close: function ($event) {
                      _vm.showDialog = -1
                    },
                  },
                })
              : _vm._e(),
            _c(
              "p",
              { staticClass: "folder", on: { click: _vm.creatChildFile } },
              [
                _c("span", { staticClass: "iconfont icon-doc" }),
                _c("span", { staticClass: "title" }, [_vm._v("新建子级文件")]),
              ]
            ),
            _vm.showDialog === 3
              ? _c("Dialog", {
                  attrs: { type: "childFile" },
                  on: {
                    close: function ($event) {
                      _vm.showDialog = -1
                    },
                  },
                })
              : _vm._e(),
            _c("p", { staticClass: "folder", on: { click: _vm.rename } }, [
              _c("span", { staticClass: "iconfont icon-edit" }),
              _c("span", { staticClass: "title" }, [_vm._v("重命名")]),
            ]),
            _vm.showDialog === 4
              ? _c("Dialog", {
                  attrs: { type: "rename" },
                  on: {
                    close: function ($event) {
                      _vm.showDialog = -1
                    },
                  },
                })
              : _vm._e(),
            _c("p", { staticClass: "folder", on: { click: _vm.remove } }, [
              _c("span", { staticClass: "iconfont icon-delete" }),
              _c("span", { staticClass: "title" }, [_vm._v("删除")]),
            ]),
            _vm.showDialog === 5
              ? _c("Dialog", {
                  attrs: { type: "remove" },
                  on: {
                    close: function ($event) {
                      _vm.showDialog = -1
                    },
                  },
                })
              : _vm._e(),
            _c("p", { staticClass: "folder", on: { click: _vm.uploadFile } }, [
              _c("span", { staticClass: "iconfont icon-upload" }),
              _c("span", { staticClass: "title" }, [_vm._v("上传")]),
            ]),
            _vm.showDialog === 6
              ? _c("Dialog", {
                  attrs: { type: "upload" },
                  on: {
                    close: function ($event) {
                      _vm.showDialog = -1
                    },
                  },
                })
              : _vm._e(),
            _c("div", {
              class: [
                "v-popover-arrow",
                _vm.placement == "top"
                  ? "v-popover-arrow-top"
                  : "v-popover-arrow-bottom",
              ],
              style: { left: _vm.arrowLeft + "px" },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }