<template>
  <div class="floder">
    <div class="cell"
         v-for="(item,index) in myCaseArray"
         :key="index"
         :class="{hasChildren:item.children}">
      <p class="title"
         @click="toChild(item,index)">
        <span class="iconfont icon-folder"
              v-if="item.hasChildren"></span>
        <span class="iconfont icon-doc"
              v-if="!item.hasChildren"></span>
        <span> {{item.content}}</span>
      </p>
      <div class="v-popover-wrap"
           :style="{left: x + 'px', top: y + 'px', visibility: show ? 'visible' : 'hidden'}">
        <!-- <div class="v-popover-box"> -->
        <div class="item"
             v-for="r in item.reference"
             :key="r.index">
          <p class="sort"
             @click="sortName(r)">
            <router-link to='/detailPage'>
              <span class="name">{{r.name}}</span>
              <span class="iconfont icon-qq"></span>
              <span class="iconfont icon-wechat"></span>
            </router-link>
          </p>
        </div>
        <!-- </div> -->
      </div>
      <div class="v-popover-tag"
           @click="pop($event,item)">
        <span class="iconfont icon-mine"></span>
      </div>
      <!-- <el-popover
        placement="bottom"
        width="160"
        v-model="item.index==-1">
       <div v-for="r in item.reference" :key="r.index">
         <router-link to="/detailPage">
         <p @click="sortName(r)">
         <span>{{r.name}}</span>
         <span class="iconfont icon-qq"></span>
           <span class="iconfont icon-wechat"></span>
         </p></router-link>
       </div>
        <el-button slot="reference" @click="showUser(item)"><span class="iconfont icon-users"></span></el-button>
      </el-popover> -->

    </div>

  </div>
</template>
<script>
export default {
  props: ['myCaseArray', 'showItem'],
  data () {
    return {
      show: false,
      arrowLeft: 0,
      x: 0,
      y: 0
    }
  },
  // props:{
  //   type:''
  // },
  methods: {
    toChild (item, index) {
      if (item.children) {
        this.$emit('toChild', index)
      } else {
        console.log('没有下一级了')
      }
    },
    sortName (r) {
      this.$store.commit('detailItem', r)
      console.log(r)
    },
    pop (e, item) {
      console.log(item)
      this.$store.commit('detailArr', item.reference)
      if (this.show) {
        this.show = false
        return
      }
      var target = e.target
      this.arrowLeft = target.offsetWidth / 2
      this.x = target.offsetLeft / 2 + 5
      if (this.placement === 'top') {
        this.y = target.offsetTop - this.$refs.pop.offsetHeight - 3
      } else {
        this.y = target.offsetTop + target.offsetHeight + 3
      }
      this.show = true
    }

  },
  mounted () {
    console.log(this.myCaseArray)
    console.log(this.showItem)
    console.log(this.$store.state.userArr)
  }
}
</script>
<style scoped>
.main {
  display: inline-block;
}
.v-popover-tag {
  display: inline-block;
}
span {
  display: inline-block;
}
p {
  display: inline-block;
  margin: 0;
  padding: 5px;
}
p.sort {
  padding: 0;
  display: flex;
  justify-content: space-around;
}
.icon {
  display: block;
}
.cell {
  padding: 5px;
  border-bottom: solid 1px #eeeeee;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}
.cell > span {
  border: none;
  float: right;
}
.el-button {
  border: none;
}
a {
  color: #1488ff;
  text-decoration: none;
  width: 100%;
}
a {
  display: flex;
  justify-content: space-around;
}
.itemcontainer {
  border: #1488ff solid 1px;
}
.v-popover-wrap {
  width: 45vw;
  position: absolute;
}
.v-popover-box {
  z-index: 2;
  background-clip: padding-box;
  border: 1px solid #eee;
  border-radius: 6px;
  /* box-shadow: 0 1px 6px rgba(99,99,99,.2); */
  position: relative;
  background: #fff;
}
.v-popover-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  margin-left: -5px;
}
.v-popover-arrow:after {
  content: " ";
  margin-left: -4px;
  border: 4px solid transparent;
  position: absolute;
  width: 0;
  height: 0;
}
.v-popover-arrow-top {
  border-bottom-width: 0;
  border-top-color: #1488ff;
  bottom: -5px;
}
.v-popover-arrow-top:after {
  border-top-color: #fff;
  bottom: -3px;
}
.item {
  width: 100%;
}
p.sort {
  display: block;
  border: #fff;
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  margin: 5px 15px;
}
.item span {
  color: #1488ff;
  font-size: 0.4rem;
}
.item .name {
  color: #000;
}
</style>
