<template>
  <div class="main">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body"
                 v-if="type=='folder'">
              <form></form>
              <p class="header">请输入文件夹名</p>
              <p class="input"> <input type="text"
                       v-model="foldername"
                       required></p>
              <p class="btn">
                <button class="cancle"
                        @click="cancle">取消</button>
                <button class="confirm"
                        @click="confirmFolder">确定</button>
              </p>
            </div>

            <div class="modal-body"
                 v-if="type=='childFolder'">
              <p class="header">请输入子级文件夹名</p>
              <p class="input"> <input type="text"
                       v-model="foldername"
                       required></p>
              <p class="btn">
                <button class="cancle"
                        @click="cancle">取消</button>
                <button class="confirm"
                        @click="confirmChildFolder">确定</button>
              </p>
            </div>

            <div class="modal-body"
                 v-if="type=='file'">
              <p class="header">请输入文件名</p>
              <p class="input"> <input type="text"
                       v-model="foldername"
                       required></p>
              <p class="btn">
                <button class="cancle"
                        @click="cancle">取消</button>
                <button class="confirm"
                        @click="confirmFile">确定</button>
              </p>
            </div>

            <div class="modal-body"
                 v-if="type=='childFile'">
              <p class="header">请输入子级文件名</p>
              <p class="input"> <input type="text"
                       v-model="foldername"
                       required></p>
              <p class="btn">
                <button class="cancle"
                        @click="cancle">取消</button>
                <button class="confirm"
                        @click="confirmChildFile">确定</button>
              </p>
            </div>

            <div class="modal-body"
                 v-if="type=='rename'">
              <p class="header">请输入新名称</p>
              <p class="input"> <input type="text"
                       v-model="foldername"
                       required></p>
              <p class="btn">
                <button class="cancle"
                        @click="cancle">取消</button>
                <button class="confirm"
                        @click="confirmName">确定</button>
              </p>
            </div>

            <div class="modal-body"
                 v-if="type=='remove'">
              <p class="header">确认删除?</p>
              <p class="btn">
                <button class="cancle"
                        @click="cancle">取消</button>
                <button class="confirm"
                        @click="confirmDelete">确定</button>
              </p>
            </div>

            <div class="modal-body"
                 v-if="type=='upload'">
              <form action="">
                <input type="file">
              </form>
              <p class="btn">
                <button class="cancle"
                        @click="cancle">取消</button>
                <button class="confirm"
                        @submit="upload">确定</button>
              </p>
            </div>

          </div>
        </div>
      </div>

    </transition>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import axios from 'axios'
export default {
  name: 'Dialog',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      foldername: ''
    }
  },
  methods: {
    cancle () {
      this.$emit('close')
    },
    confirmFolder () {
      console.log('entry?')
      axios.post(caseBase + '/farbun/myTree/addSubdirectory?pId=' + this.$store.state.folder.pId + '&userId=100&text=' + this.foldername + '&file=true')
        .then(res => {
          if (res.data.code === 200) {
            this.$emit('close')
            this.foldername = ''
            console.log('新增成功！')
          }
        })
    },
    confirmChildFolder () {
      axios.post(caseBase + '/farbun/myTree/addSubdirectory?pId=' + this.$store.state.folder.id + '&userId=100&text=' + this.foldername + '')
        .then(res => {
          if (res.data.code === 200) {
            this.$emit('close')
            this.foldername = ''
            console.log('新增成功！')
          }
        })
    },
    confirmName () {
      axios.put(caseBase + '/farbun/myTree/rename?id=' + this.$store.state.folder.id + '&userId=100&text=' + this.foldername + ' ')
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            this.$emit('close')
            this.foldername = ''
          }
        })
    },
    confirmDelete () {
      axios.delete(caseBase + '/farbun/myTree/delete?userId=100&id=' + this.$store.state.folder.id + '')
        .then(res => {
          if (res.data.code === 200) {
            this.$emit('close')
            this.foldername = ''
          }
        })
    },
    upload () {
      console.log('hello world')
    }
  }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60vw;
  margin: 0px auto;
  padding: 20px 30px 0px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  /* margin: 20px 0; */
  text-align: center;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.header {
  font-size: 15px;
}
p.btn {
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  margin: 5vw 0;
  width: 100%;
  border-top: #eee solid 1px;
}
input {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #eee;
  height: 1rem;
}
.cancle,
.confirm {
  border: none;
  background: #fff;
  width: 50%;
  margin: 10px;
  /* margin-top: 10px; */
}
.cancle {
  border-right: #eee solid 1px;
}
span.iconfont {
  padding-right: 5px;
}
</style>
