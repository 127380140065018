var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "floder" },
    _vm._l(_vm.myCaseArray, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "cell",
          class: { hasChildren: item.children },
        },
        [
          _c(
            "p",
            {
              staticClass: "title",
              on: {
                click: function ($event) {
                  return _vm.toChild(item, index)
                },
              },
            },
            [
              item.hasChildren
                ? _c("span", { staticClass: "iconfont icon-folder" })
                : _vm._e(),
              !item.hasChildren
                ? _c("span", { staticClass: "iconfont icon-doc" })
                : _vm._e(),
              _c("span", [_vm._v(" " + _vm._s(item.content))]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "v-popover-wrap",
              style: {
                left: _vm.x + "px",
                top: _vm.y + "px",
                visibility: _vm.show ? "visible" : "hidden",
              },
            },
            _vm._l(item.reference, function (r) {
              return _c("div", { key: r.index, staticClass: "item" }, [
                _c(
                  "p",
                  {
                    staticClass: "sort",
                    on: {
                      click: function ($event) {
                        return _vm.sortName(r)
                      },
                    },
                  },
                  [
                    _c("router-link", { attrs: { to: "/detailPage" } }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(r.name)),
                      ]),
                      _c("span", { staticClass: "iconfont icon-qq" }),
                      _c("span", { staticClass: "iconfont icon-wechat" }),
                    ]),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "v-popover-tag",
              on: {
                click: function ($event) {
                  return _vm.pop($event, item)
                },
              },
            },
            [_c("span", { staticClass: "iconfont icon-mine" })]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }