<template>
  <div class="main">
    <div class="search">
      <i class="iconfont icon-search"></i>
      <input type="search"
             @change="Select()"
             v-model="searchValue"
             placeholder="检索案件信息">
    </div>
    <div class="classify">
      <span class="iconfont icon-back"
            @click="moveLeft"></span>
      <span class="classes-0"
            @click="firstType"
            :class="{'active':this.selected==1}">民事</span>
      <span class="classes-1"
            @click="secondType"
            :class="{'active':this.selected==2}">刑事</span>
      <span class="classes-2"
            @click="thirdType"
            :class="{'active':this.selected==3}">执行</span>
      <span class="classes-3"
            @click="forthType"
            :class="{'active':this.selected==4}">行政</span>
      <span class="iconfont icon-gengduo"
            @click="moveRight"></span>
    </div>

    <span @click="toBack">...</span>
    <template v-if="!data.datas">
      <div class="cell"
           v-for="(item,index) in data"
           :key="index"
           :class="{hasChildren:item.children}">
        <p class="item">
          <span class="iconfont icon-folder"
                v-if="item.children"></span>
          <span class="iconfont icon-doc"
                v-if="!item.children"></span>
          <span @click="enterDetail(item)"
                v-html="item.text.replace(searchValue,`<span style='color:red'>${searchValue}</span>`)">
            {{item.text}}
          </span>
        </p>
        <p class="icon"
           @click="confirmFolder(item)">
          <Pop-card :placement="popPlacement">
            <span class="iconfont icon-edit-2"
                  @click.native="confirmFolder(item)"></span>
          </Pop-card>

        </p>
      </div>
    </template>
    <div class="load"
         @click="loadMore"
         v-if="!data.datas">加载更多</div>
    <div class="detail"
         v-html="data.datas"
         v-if="data.datas"></div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import PopCard from './PopCard'
import axios from 'axios'
export default {
  name: 'CaseFile',
  data () {
    return {
      searchValue: '',
      currentId: '',
      ShowChild: false,
      ShowDetail: false,
      controller: '',
      selected: 1,
      typeArr: [],
      indexArray: [],
      criminalData: [],
      civilData: [],
      implementData: [],
      administrativeData: [],
      page: 1,
      data: [
      ],
      popPlacement: 'bottom'
    }
  },
  components: {
    'Pop-card': PopCard
  },
  methods: {
    confirmFolder (item) {
      console.log('hello')
      this.$store.commit('currentData', this.data)
      this.$store.commit('confirmFolder', item)
    },
    Select () {
      console.log('input')
      axios.get(caseBase + '/farbun/myTree/search?title=' + this.searchValue + '&userId=100')
        .then((res) => {
          console.log(res, '???')
          console.log(res.data.data)
          this.data = res.data.data.children
        })
    },
    firstType () {
      this.selected = 1
      console.log(this.typeArr)
      axios.get(caseBase + '/farbun/myTree/list?userId=100&pId=' + this.typeArr[0].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.civilData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    secondType () {
      this.selected = 2
      axios.get(caseBase + '/farbun/myTree/list?userId=100&pId=' + this.typeArr[1].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.criminalData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    thirdType () {
      this.selected = 3
      axios.get(caseBase + '/farbun/myTree/list?userId=100&pId=' + this.typeArr[2].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.implementData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    forthType () {
      this.selected = 4
      axios.get(caseBase + '/farbun/myTree/list?userId=100&pId=' + this.typeArr[3].id + '')
        .then(res => {
          console.log(res)
          this.data = res.data.data.children
          this.administrativeData = res.data.data.children
          this.indexArray = [...this.indexArray, this.data]
          console.log(this.data)
        })
    },
    moveLeft () {
      console.log('move left')
      this.selected = this.selected - 1
      if (this.selected === 0) {
        this.selected = 4
      };
      console.log('pass')
      switch (this.selected) {
        case 1:
          this.firstType()
          break
        case 2:
          this.secondType()
          break
        case 3:
          this.thirdType()
          break
        case 4:
          this.forthType()
          break
        default:
          console.log(this.selected)
      }
    },
    moveRight () {
      console.log('move right')
      this.selected = this.selected + 1
      if (this.selected === 5) {
        this.selected = 1
      };
      switch (this.selected) {
        case 1:
          this.firstType()
          break
        case 2:
          this.secondType()
          break
        case 3:
          this.thirdType()
          break
        case 4:
          this.forthType()
          break
        default:
          console.log(this.selected)
      }
    },

    enterDetail (item) {
      this.currentId = item.id
      console.log(this.currentId)
      if (item.children) {
        axios.get(caseBase + '/farbun/myTree/list?userId=100&pId=' + item.id + '')
          .then((res) => {
            console.log(res, '???')
            console.log(res.data.data)
            this.data = res.data.data.children
            this.indexArray = [...this.indexArray, this.data]
            // this.indexArray.push(this.data);
            console.log(this.indexArray)
            console.log(this.indexArray[this.indexArray.length - 1])
          })
      } else {
        axios.get(caseBase + '/farbun/myTree/document?documentId=12b5d1c5-ded9-4ac7-85c5-d9e06def8971&userId=100')
          .then((res) => {
            console.log(res, '???')
            console.log(res.data.data)
            this.data = res.data.data
          })
      }
      // this.$store.state.indexArray.push(index);
    },
    toBack () {
      if (this.indexArray.length > 1) {
        this.data = this.indexArray[this.indexArray.length - 1]
        this.indexArray.pop()
      } else {
        console.log(this.data)
        switch (this.selected) {
          case 1:
            this.data = this.civilData
            break
          case 2:
            this.data = this.criminalData
            break
          case 3:
            this.data = this.administrativeData
            break
          case 4:
            this.data = this.implementData
            break
          default:
            this.data = this.civilData
        }
      }
    },

    loadMore () {
      let page = Math.ceil(this.data.length / 10)
      page++
      console.log(this.currentId)
      axios.get(caseBase + '/farbun/myTree/queryMore?id=' + this.currentId + '&userId=100&currPage=' + page + '')
        .then(res => {
          console.log(res.data.data)
          if (res.data.data.children.length) {
            console.log(res.data.data)
            this.data = this.data.concat(res.data.data.children)
            console.log(this.data)
          } else {
            console.log('没有更多了')
          }
        })
    }
  },
  computed: {
    calcArray () {
      console.log('hellp')
      this.$store.commit('testarr')
      this.$store.state.indexArray.forEach((item, index) => {
        // array = array[item].children;
        this.$store.commit('newArr', item)
      })
      return this.$store.state.array
    }
    // newarr(){
    //     var testarr= this.tests.filter(test=>test.name.includes(this.searchValue));
    //   console.log(typeof(testarr[1].content))
    //     console.log(typeof(testarr[1].content)==="string")
    //   console.log(testarr[1].content)
    //     return testarr;
    // }
  },
  mounted () {
    axios.get(caseBase + '/farbun/myTree/top?userId=0')
      .then(res => {
        console.log(res.data.data)
        this.typeArr = res.data.data
      })
    axios.get(caseBase + '/farbun/myTree/search?title=' + '纠纷' + '&userId=100 ')
      .then(res => {
        console.log(res.data.data)
        this.data = res.data.data.children
      })
  }
}
</script>
<style scoped>
.load {
  text-align: center;
}
div.search {
  margin: 5px;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 5px;
}
input {
  display: inline-block;
  height: 20px;
  padding: 0;
  margin: 0;
  width: 80vw;
  border: none;
  text-decoration: none;
}
input:focus {
  border: none;
  outline: none;
}

span {
  padding: 5px;
  display: inline-block;
  /* font-size: 0.5rem; */
}
.active {
  color: #1488ff;
}
p.icon {
  width: 15vw;
  display: inline-block;
}
p.icon span {
  padding: 0;
}
button {
  padding: 8px;
}
p.title {
  width: 65vw;
}
.classify {
  display: flex;
  border: 1px solid #1488ff;
  border-radius: 5px;
  margin: 5px;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
}
.classify > span {
  font-size: 0.5rem;
}
p {
  display: inline-block;
  margin: 0;
}
.icon {
  float: right;
}
.cell {
  padding: 5px;
  border-bottom: solid 1px #eeeeee;
  margin: 10px;
}
.v-popover-box p {
  margin: 5px;
}
</style>
