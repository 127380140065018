<template>
  <div class="main">
    <div class="v-popover-tag" @click="pop($event)">
      <slot></slot>
    </div>
    <div class="v-popover-wrap" :style="{left: x + 'px', top: y + 'px', visibility: show ? 'visible' : 'hidden'}" ref="pop">
      <div class="v-popover-box">
        <p class="folder" @click="creatFolder">
          <span class="iconfont icon-folder"></span>
          <span class="title">新建文件夹</span>
        </p>
        <Dialog v-if="showDialog===0" @close="showDialog=-1" type='folder'></Dialog>
        <p class="folder" @click="creatChildFolder">
          <span class="iconfont icon-folder"></span>
          <span class="title">新建子级文件夹</span>
        </p>
        <Dialog v-if="showDialog===1" @close="showDialog=-1" type='childFolder'></Dialog>
        <p class="folder" @click="creatFile">
          <span class="iconfont icon-doc"></span>
          <span class="title">新建文件</span>
        </p>
        <Dialog v-if="showDialog===2" @close="showDialog=-1" type='file'></Dialog>
        <p class="folder" @click="creatChildFile">
          <span class="iconfont icon-doc"></span>
          <span class="title">新建子级文件</span>
        </p>
        <Dialog v-if="showDialog===3" @close="showDialog=-1" type='childFile'></Dialog>

        <p class="folder" @click="rename">
          <span class="iconfont icon-edit"></span>
          <span class="title">重命名</span>
        </p>
        <Dialog v-if="showDialog===4" @close="showDialog=-1" type='rename' ></Dialog>

        <p class="folder" @click="remove">
          <span class="iconfont icon-delete"></span>
          <span class="title">删除</span>
        </p>
        <Dialog v-if="showDialog===5" @close="showDialog=-1" type='remove'></Dialog>

        <p class="folder" @click="uploadFile">
          <span class="iconfont icon-upload"></span>
          <span class="title">上传</span>
        </p>
        <Dialog v-if="showDialog===6" @close="showDialog=-1" type='upload'></Dialog>
        <!-- <form method="get" action="http://192.168.2.127:8082/faben/myTree/uploadFile?pId=d950c4ed-2c89-48e6-835d-b886ac76ab4b&userId=100-->
        <!--" enctype="multipart/form-data">
          <input type="file" placeholder="" style="width: 20vw; border: none" @change="upload($event)">
            <input type="submit" value="上传">
          </form> -->

        <div :class="['v-popover-arrow', placement == 'top' ? 'v-popover-arrow-top' : 'v-popover-arrow-bottom']" :style="{left: arrowLeft + 'px'}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from './Dialog'
export default {
  name: 'Pop-card',
  props: {
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  data () {
    return {
      show: false,
      arrowLeft: 0,
      x: 0,
      y: 0,
      showDialog: -1
    }
  },
  components: {
    Dialog: Dialog
  },
  methods: {
    pop (e) {
      if (this.show) {
        this.show = false
        return
      }
      var target = e.target
      this.arrowLeft = target.offsetWidth / 2
      this.x = target.offsetLeft / 2
      if (this.placement === 'top') {
        this.y = target.offsetTop - this.$refs.pop.offsetHeight - 3
      } else {
        this.y = target.offsetTop + target.offsetHeight + 3
      }
      this.show = true
    },
    creatFolder () {
      this.showDialog = 0
    },
    creatChildFolder () {
      this.showDialog = 1
    },
    creatFile () {
      this.showDialog = 2
    },
    creatChildFile () {
      this.showDialog = 3
    },
    rename () {
      this.showDialog = 4
    },
    remove () {
      this.showDialog = 5
    },
    uploadFile () {
      this.showDialog = 6
    }

  }
}
</script>

<style scoped>
.v-popover-wrap {
  width: 40vw;
  position: absolute;
}
.v-popover-box::before {
    content:'';
    /* border: 8px solid #1488ff; */
    color: #1488ff;
    transform: rotate(45deg);
    border-radius: 2px 5px 4px 4px ;
    border-bottom-left-radius: 5px;
    position: absolute;
    border-bottom: none;
    z-index: 1;
    top: -15px;
    left: 90%;
}
.v-popover-box {
  background-clip: padding-box;
  border: 1px solid #1488ff;
  border-radius: 6px;
  box-shadow: 0 1px 6px rgba(99, 99, 99, 0.2);
  position: relative;
  z-index: 10;
  background: #fff;
}
.v-popover-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  margin-left: -5px;
  z-index: 10;
}
.v-popover-arrow:after {
  content: " ";
  margin-left: -4px;
  border: 4px solid transparent;
  position: absolute;
  width: 0;
  height: 0;
  z-index: 10;
}
.v-popover-arrow-top {
  border-bottom-width: 0;
  border-top-color: #d9d9d9;
  bottom: -5px;
  z-index: 10;
}
.v-popover-arrow-top:after {
  border-top-color: #fff;
  bottom: -3px;
  z-index: 10;
}

.title {
  font-size: 15px;
}
p {
  border-bottom: #eee solid 1px;
  margin: 10px;
}
span.iconfont {
  color: #1488ff;
  font-weight: 400;
  font-size: 0.5rem
}
.icon .main {
    margin: 0
}
</style>
