var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("div", { staticClass: "search" }, [
        _c("i", { staticClass: "iconfont icon-search" }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchValue,
              expression: "searchValue",
            },
          ],
          attrs: { type: "search", placeholder: "检索案件信息" },
          domProps: { value: _vm.searchValue },
          on: {
            change: function ($event) {
              return _vm.Select()
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.searchValue = $event.target.value
            },
          },
        }),
      ]),
      _c("div", { staticClass: "classify" }, [
        _c("span", {
          staticClass: "iconfont icon-back",
          on: { click: _vm.moveLeft },
        }),
        _c(
          "span",
          {
            staticClass: "classes-0",
            class: { active: this.selected == 1 },
            on: { click: _vm.firstType },
          },
          [_vm._v("民事")]
        ),
        _c(
          "span",
          {
            staticClass: "classes-1",
            class: { active: this.selected == 2 },
            on: { click: _vm.secondType },
          },
          [_vm._v("刑事")]
        ),
        _c(
          "span",
          {
            staticClass: "classes-2",
            class: { active: this.selected == 3 },
            on: { click: _vm.thirdType },
          },
          [_vm._v("执行")]
        ),
        _c(
          "span",
          {
            staticClass: "classes-3",
            class: { active: this.selected == 4 },
            on: { click: _vm.forthType },
          },
          [_vm._v("行政")]
        ),
        _c("span", {
          staticClass: "iconfont icon-gengduo",
          on: { click: _vm.moveRight },
        }),
      ]),
      _c("span", { on: { click: _vm.toBack } }, [_vm._v("...")]),
      !_vm.data.datas
        ? _vm._l(_vm.data, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "cell",
                class: { hasChildren: item.children },
              },
              [
                _c("p", { staticClass: "item" }, [
                  item.children
                    ? _c("span", { staticClass: "iconfont icon-folder" })
                    : _vm._e(),
                  !item.children
                    ? _c("span", { staticClass: "iconfont icon-doc" })
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      domProps: {
                        innerHTML: _vm._s(
                          item.text.replace(
                            _vm.searchValue,
                            `<span style='color:red'>${_vm.searchValue}</span>`
                          )
                        ),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.enterDetail(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.text) + " ")]
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "icon",
                    on: {
                      click: function ($event) {
                        return _vm.confirmFolder(item)
                      },
                    },
                  },
                  [
                    _c("Pop-card", { attrs: { placement: _vm.popPlacement } }, [
                      _c("span", {
                        staticClass: "iconfont icon-edit-2",
                        nativeOn: {
                          click: function ($event) {
                            return _vm.confirmFolder(item)
                          },
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ]
            )
          })
        : _vm._e(),
      !_vm.data.datas
        ? _c("div", { staticClass: "load", on: { click: _vm.loadMore } }, [
            _vm._v("加载更多"),
          ])
        : _vm._e(),
      _vm.data.datas
        ? _c("div", {
            staticClass: "detail",
            domProps: { innerHTML: _vm._s(_vm.data.datas) },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }