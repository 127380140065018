var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "NavBar",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c("fb-button", {
            attrs: {
              slot: "right",
              icon: "menu",
              size: "small",
              type: "primary",
            },
            slot: "right",
          }),
        ],
        1
      ),
      _c("p", { staticClass: "tabbar" }, [
        _c(
          "span",
          {
            staticClass: "tab",
            class: { active: _vm.Switch === 1 },
            on: { click: _vm.ShowCase },
          },
          [_vm._v("案件档案")]
        ),
        _c(
          "span",
          {
            staticClass: "tab",
            class: { active: _vm.Switch === 2 },
            on: { click: _vm.ShowSystem },
          },
          [_vm._v("系统知识")]
        ),
        _c(
          "span",
          {
            staticClass: "tab",
            class: { active: _vm.Switch === 3 },
            on: { click: _vm.ShowMine },
          },
          [_vm._v("我的知识")]
        ),
      ]),
      _c("CaseFile", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.Switch === 1,
            expression: "Switch===1",
          },
        ],
      }),
      _c("SystemKnowledge", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.Switch === 2,
            expression: "Switch===2",
          },
        ],
      }),
      _c("MyKnowledge", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.Switch === 3,
            expression: "Switch===3",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }